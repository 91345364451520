import {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    FCFieldConfig,
    FormConverterBase,
} from '../library/FormConverter';

type FieldNameSC = 'sensorId' | 'sensorIdLegacy'; // * add more fields... example: 'a' | 'b' | 'c' | 'd'

export class SensorConfigurationFormConverter extends FormConverterBase<FieldNameSC> {
    public readonly FieldConfig: Readonly<FCFieldConfig<FieldNameSC>> = {
        sensorId: {
            placeholderChar: '_',
            charsRegex: {
                blacklist: /[^A-Za-z0-9 ._-]/g, // ? negated group expression
                whitelist: /[A-Za-z0-9 ._-]/g,
            },
        },
        sensorIdLegacy: {
            placeholderChar: '_',
            charsRegex: {
                blacklist: /[^A-Za-z0-9 ._:&="'\(\)\[\]\-]/g, // ? negated group expression
                whitelist: /[A-Za-z0-9 ._:&="'\(\)\[\]\-]/g,
            },
        },
        // * add more fields...
    };
}
