import { haversine } from './geometry';

export const FeatureType = 'Feature';
export const FeatureCollectionType = 'FeatureCollection';
export const GeometryCollectionType = 'GeometryCollection';
export const GeometryType = 'Geometry';
export const PointType = 'Point';
export const MultiPointType = 'MultiPoint';
export const LineStringType = 'LineString';
export const MultiLineStringType = 'MultiLineString';
export const PolygonType = 'Polygon';
export const MultiPolygonType = 'MultiPolygon';

export declare type Extent = [number, number, number, number];
export declare type Coords =
    | number[]
    | number[][]
    | number[][][]
    | number[][][][];

export interface Geometry {
    type: string;
    coordinates: Coords;
}

export interface GeometryCollection {
    type: string;
    features: Feature[];
}

export interface Feature {
    type: string;
    geometry: Geometry | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    properties: { [key: string]: any } | undefined;
}

export interface FeatureCollection {
    type: string;
    features: Feature[];
}

// get lon/lat alternating list
// Assumes lon / lat order is honoured, would work for east / northings
export const lonLatPosition = (position: number[]): number[] =>
    position.length > 1 ? [position[0], position[1]] : [];
// get lon/lat alternating list
// Assumes lon / lat order is honoured, would work for east / northings
export const lonLatPositions = (positions: number[][]): number[] =>
    positions.flatMap((p) => lonLatPosition(p));
// get lon/lat alternating list
// Assumes lon / lat order is honoured, would work for east / northings
export const lonLatPositions2 = (positions: number[][][]): number[] =>
    positions.flatMap((p) => lonLatPositions(p));
// get lon/lat alternating list
// Assumes lon / lat order is honoured, would work for east / northings
export const lonLatPositions3 = (positions: number[][][][]): number[] =>
    positions.flatMap((p) => lonLatPositions2(p));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPosition = (coords: any[]): boolean =>
    (coords.length === 2 || coords.length === 3) &&
    !Array.isArray(coords[0]) &&
    !Array.isArray(coords[1]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPositions = (coords: any[]): boolean =>
    Array.isArray(coords) && !coords.some((c) => !isPosition(c));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPositions2 = (coords: any[]): boolean =>
    Array.isArray(coords) && !coords.some((c) => !isPositions(c));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPositions3 = (coords: any[]): boolean =>
    Array.isArray(coords) && !coords.some((c) => !isPositions2(c));

// get lon/lat alternating list
// Assumes lon / lat order is honoured, would work for east / northings
export const lonLatGeometry = (geometry: Geometry): number[] => {
    if (validGeometry(geometry)) {
        if (geometry.type === PointType) {
            return lonLatPosition(geometry.coordinates as number[]);
        } else if (
            geometry.type === LineStringType ||
            geometry.type === MultiPointType
        ) {
            return lonLatPositions(geometry.coordinates as number[][]);
        } else if (
            geometry.type === PolygonType ||
            geometry.type === MultiLineStringType
        ) {
            return lonLatPositions2(geometry.coordinates as number[][][]);
        } else if (geometry.type === MultiPolygonType) {
            return lonLatPositions3(geometry.coordinates as number[][][][]);
        }
    }

    return [];
};

// get lon/lat alternating list
// Assumes lon / lat order is honoured, would work for east / northings
export const lonLatFeature = (feature: Feature): number[] =>
    feature.type == FeatureType && feature.geometry
        ? lonLatGeometry(feature.geometry)
        : [];

// get lon/lat alternating list
// Assumes lon / lat order is honoured, would work for east / northings
export const lonLatFeatures = (features: Feature[]): number[] =>
    features.flatMap((f) => lonLatFeature(f));

const lonExpandExtent = (lon: number, extent: Extent): Extent => {
    if (lon < extent[0]) {
        extent[0] = lon;
    }
    if (lon > extent[2]) {
        extent[2] = lon;
    }
    return extent;
};

const latExpandExtent = (lat: number, extent: Extent): Extent => {
    if (lat < extent[1]) {
        extent[1] = lat;
    }
    if (lat > extent[3]) {
        extent[3] = lat;
    }
    return extent;
};

// extent built from alternating lon/lat list
//    Assumes lon/lat order is honoured, would work for east/northings
//    Dont throw if info missing only if geojson badly formed
//    returns undefined if no lon/lats foun
export const lonLatExtent = (lonLats: number[]): Extent | undefined => {
    if (lonLats.length < 2) {
        return undefined;
    }

    let extent: Extent = [lonLats[0], lonLats[1], lonLats[0], lonLats[1]];

    lonLats.forEach(
        (ll, n) =>
            (extent =
                n % 2 !== 0
                    ? latExpandExtent(ll, extent)
                    : lonExpandExtent(ll, extent)),
    );

    return extent;
};

// Build extent from Feature[]
// Assumes lon / lat order is honoured, would work for east / northings
// returns undefined if no lon / lats found
export const featuresExtent = (features: Feature[]): Extent | undefined =>
    lonLatExtent(lonLatFeatures(features));

// Build extent from Geometry[]
// Assumes lon / lat order is honoured, would work for east / northings
// returns undefined if no lon / lats found
export const geometriesExtent = (geometries: Geometry[]): Extent | undefined =>
    lonLatExtent(geometries.flatMap((g) => lonLatGeometry(g)));

export const centreExtent = (
    extent: Extent | undefined,
): [number, number] | undefined =>
    extent
        ? [0.5 * (extent[0] + extent[2]), 0.5 * (extent[1] + extent[3])]
        : undefined;

export const expandExtent = (
    lonLat: [number, number],
    extent: Extent | undefined = undefined,
): Extent =>
    extent
        ? [
              Math.min(lonLat[0], extent[0]),
              Math.min(lonLat[1], extent[1]),
              Math.max(lonLat[0], extent[2]),
              Math.max(lonLat[1], extent[3]),
          ]
        : [lonLat[0], lonLat[1], lonLat[0], lonLat[1]];

export const expandExtents = (b1: Extent, b2: Extent): Extent => [
    Math.min(b1[0], b2[0]),
    Math.min(b1[1], b2[1]),
    Math.max(b1[2], b2[2]),
    Math.max(b1[3], b2[3]),
];

export const coordInExtent = (c: [number, number], e: Extent): boolean =>
    c[0] >= e[0] && c[0] <= e[2] && c[1] >= e[1] && c[1] <= e[3];

export const extentPointsInExtent = (coords: Extent, extent: Extent): boolean =>
    coordInExtent([coords[0], coords[1]], extent) ||
    coordInExtent([coords[2], coords[1]], extent) ||
    coordInExtent([coords[2], coords[3]], extent) ||
    coordInExtent([coords[0], coords[3]], extent);

// Test both ways to cover case of one extent being completly within the other.
export const extentsOverlap = (e1: Extent, e2: Extent): boolean =>
    extentPointsInExtent(e1, e2) || extentPointsInExtent(e2, e1);

// that is in or on
export const inExtent = (
    geometry: Geometry | undefined,
    extent?: Extent | undefined,
): boolean => {
    if (!geometry || !validGeometry(geometry)) {
        return false;
    }

    if (!extent) {
        return true; // let all through if missing
    }

    const gExtent = geometriesExtent([geometry]);

    return gExtent ? extentsOverlap(gExtent, extent) : false;
};

export const within = (
    centre: [number, number],
    radInM: number,
    geometry: Geometry | undefined,
): boolean => {
    if (geometry && validGeometry(geometry) && radInM > 0) {
        const lonLats = lonLatGeometry(geometry);

        for (let n = 0; n < lonLats.length; n += 2) {
            if (
                haversine(centre[0], centre[1], lonLats[n], lonLats[n + 1]) <
                radInM
            ) {
                return true;
            }
        }
    }

    return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validGeometry = (o: any): boolean => {
    if (!o || typeof o !== 'object' || Array.isArray(o)) {
        return false;
    }

    const coords = o?.coordinates ?? [];

    if (!Array.isArray(coords) || coords.length < 1) {
        return false;
    }

    if (o.type === PointType) {
        return isPosition(coords);
    }

    if (o.type === LineStringType || o.type === MultiPointType) {
        return isPositions(coords);
    }

    if (o.type === PolygonType || o.type === MultiLineStringType) {
        return isPositions2(coords);
    }

    if (o.type === MultiPolygonType) {
        return isPositions3(coords);
    }

    return false;
};
