export const FACILITIES_TYPES_LIST = [
    'Activated Sludge',
    'Chlorination',
    'Clarifier',
    'Cogeneration',
    'Corrosion Control',
    'Disinfection',
    'District Metered Area',
    'Filtration',
    'Odor Control',
    'Pre-treatment',
    'Primary Treatment',
    'Pump Station',
    'Screening',
    'Secondary Treatment',
    'Sewer Shed',
    'Solids Handling',
    'Tertiary Treatment',
    'Wastewater Collection',
    'Wastewater Treatment',
    'Water Distribution',
    'Water Treatment',
    'Wet Weather Station',
] as const;

export type LiftStationType = typeof FACILITIES_TYPES_LIST[number];
