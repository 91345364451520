// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SENSOR_TYPE_UNITS_MAP, SensorType, SensorTypeUnits } from '../types';

export const whiteListSensorUnits = (
    ...sensorTypes: SensorType[]
): SensorTypeUnits => {
    return Object.keys(SENSOR_TYPE_UNITS_MAP)
        .filter((sensorType: SensorType) => sensorTypes.includes(sensorType))
        .reduce(
            (map: SensorTypeUnits, sensorType: SensorType) => ({
                ...map,
                [sensorType]: SENSOR_TYPE_UNITS_MAP[sensorType],
            }),
            {} as SensorTypeUnits,
        );
};
