export enum Subscription {
    INSIGHT_T1 = 'InsightT1',
    INSIGHT_T2 = 'InsightT2',
    INSIGHT_T3 = 'InsightT3',
    INSIGHT_CM = 'InsightCM',
    PLANT_T1 = 'PlantT1',
    PLANT_T2 = 'PlantT2',
    PLANT_T3 = 'PlantT3',
    PLANT_T4 = 'PlantT4',
    PLANT_CM = 'PlantCM',
    ASSET_FULL_ACCESS = 'AssetFullAccess',
    ASSET_VIEWER_ONLY_ACCESS = 'AssetViewerOnlyAccess',
    ASSET_INSPECTION_FIELD = 'AssetInspectionField',
    WATER_T1 = 'WaterT1',
    WATER_T2 = 'WaterT2',
    WATER_T3 = 'WaterT3',
    ICM_1D = 'ICM1D',
    ICM_1D_2D = 'ICM1D2D',
    // Above DEPRECATED innovyze access
    INFO360IU = 'INFO360IU', // ??
    INFO360DF = 'INFO360DF',
    // Below autodesk access
    I360AFA = 'I360AFA', // Asset Full Access
    I360AFI = 'I360AFI', // Asset Inspection Field
    I360AV = 'I360AV', // Asset Viewer
    I360IU = 'I360IU', // Insight Access
    I36P1CM = 'I36P1CM', // Plant Custom
    I36P1KM = 'I36P1KM', // Plant (?)
    I36P50M = 'I36P50M', // Plant 50 seats (?)
    I36P250 = 'I36P250', // Plant 250 seats (?)
    I360PU = 'I360PU', // Plant Unlimited (?)
    INNCS = 'INNCS', // Collaboration Service (Domain Services)
}

export const SUBSCRIPTION_NAMES: { [key in Subscription]: string } = {
    [Subscription.INSIGHT_T1]: 'Insight Tier 1',
    [Subscription.INSIGHT_T2]: 'Insight Tier 2',
    [Subscription.INSIGHT_T3]: 'Insight Tier 3',
    [Subscription.INSIGHT_CM]: 'Insight Custom',
    [Subscription.PLANT_T1]: 'Plant Tier 1',
    [Subscription.PLANT_T2]: 'Plant Tier 2',
    [Subscription.PLANT_T3]: 'Plant Tier 3',
    [Subscription.PLANT_T4]: 'Plant Tier 4',
    [Subscription.PLANT_CM]: 'Plant Custom',
    [Subscription.ASSET_FULL_ACCESS]: 'Asset Full Access',
    [Subscription.ASSET_VIEWER_ONLY_ACCESS]: 'Asset Viewer Only Access',
    [Subscription.ASSET_INSPECTION_FIELD]: 'Asset Inspection Field',
    [Subscription.WATER_T1]: 'Water Tier 1',
    [Subscription.WATER_T2]: 'Water Tier 2',
    [Subscription.WATER_T3]: 'Water Tier 3',
    [Subscription.ICM_1D]: 'ICM 1D',
    [Subscription.ICM_1D_2D]: 'ICM 1D 2D',
    [Subscription.INFO360IU]: 'INFO360IU',
    [Subscription.INFO360DF]: 'Info360 Demand Forecast',
    [Subscription.I360AFA]: 'I360AFA',
    [Subscription.I360AFI]: 'I360AFI',
    [Subscription.I360AV]: 'I360AV',
    [Subscription.I360IU]: 'I360IU',
    [Subscription.I36P1CM]: 'I36P1CM',
    [Subscription.I36P1KM]: 'I36P1KM',
    [Subscription.I36P50M]: 'I36P50M',
    [Subscription.I36P250]: 'I36P250',
    [Subscription.I360PU]: 'I360PU',
    [Subscription.INNCS]: 'INNCS',
};

export const internalEmailDomains = [
    'innovyze.com',
    'bytecode.io',
    'itexico.com',
    'improving.com',
    'rackspace.com',
    'helecloud.com',
    'autodesk.com',
];

export const UNLIMITED_SUBSCRIPTIONS = new Set<Subscription>();
UNLIMITED_SUBSCRIPTIONS.add(Subscription.INSIGHT_T1);
UNLIMITED_SUBSCRIPTIONS.add(Subscription.INSIGHT_T2);
UNLIMITED_SUBSCRIPTIONS.add(Subscription.INSIGHT_T3);
UNLIMITED_SUBSCRIPTIONS.add(Subscription.INSIGHT_CM);
UNLIMITED_SUBSCRIPTIONS.add(Subscription.PLANT_T1);
UNLIMITED_SUBSCRIPTIONS.add(Subscription.PLANT_T2);
UNLIMITED_SUBSCRIPTIONS.add(Subscription.PLANT_T3);
UNLIMITED_SUBSCRIPTIONS.add(Subscription.PLANT_T4);
UNLIMITED_SUBSCRIPTIONS.add(Subscription.PLANT_CM);
UNLIMITED_SUBSCRIPTIONS.add(Subscription.ASSET_VIEWER_ONLY_ACCESS);
UNLIMITED_SUBSCRIPTIONS.add(Subscription.ASSET_INSPECTION_FIELD);
UNLIMITED_SUBSCRIPTIONS.add(Subscription.ICM_1D);
UNLIMITED_SUBSCRIPTIONS.add(Subscription.ICM_1D_2D);

export const INSIGHT_SUBSCRIPTIONS = new Set<Subscription>();
INSIGHT_SUBSCRIPTIONS.add(Subscription.INSIGHT_T1);
INSIGHT_SUBSCRIPTIONS.add(Subscription.INSIGHT_T2);
INSIGHT_SUBSCRIPTIONS.add(Subscription.INSIGHT_T3);
INSIGHT_SUBSCRIPTIONS.add(Subscription.INSIGHT_CM);

export const PLANT_SUBSCRIPTIONS = new Set<Subscription>();
PLANT_SUBSCRIPTIONS.add(Subscription.PLANT_T1);
PLANT_SUBSCRIPTIONS.add(Subscription.PLANT_T2);
PLANT_SUBSCRIPTIONS.add(Subscription.PLANT_T3);
PLANT_SUBSCRIPTIONS.add(Subscription.PLANT_T4);
PLANT_SUBSCRIPTIONS.add(Subscription.PLANT_CM);
PLANT_SUBSCRIPTIONS.add(Subscription.I36P1CM);
PLANT_SUBSCRIPTIONS.add(Subscription.I36P1KM);
PLANT_SUBSCRIPTIONS.add(Subscription.I36P50M);
PLANT_SUBSCRIPTIONS.add(Subscription.I36P250);
PLANT_SUBSCRIPTIONS.add(Subscription.I360PU);

export const WATER_SUBSCRIPTIONS = new Set<Subscription>();
WATER_SUBSCRIPTIONS.add(Subscription.WATER_T1);
WATER_SUBSCRIPTIONS.add(Subscription.WATER_T2);
WATER_SUBSCRIPTIONS.add(Subscription.WATER_T3);

export const ASSET_SUBSCRIPTIONS = new Set<Subscription>();
ASSET_SUBSCRIPTIONS.add(Subscription.ASSET_FULL_ACCESS);
ASSET_SUBSCRIPTIONS.add(Subscription.ASSET_VIEWER_ONLY_ACCESS);
ASSET_SUBSCRIPTIONS.add(Subscription.ASSET_INSPECTION_FIELD);

export const ICM_SUBSCRIPTIONS = new Set<Subscription>();
ICM_SUBSCRIPTIONS.add(Subscription.ICM_1D);
ICM_SUBSCRIPTIONS.add(Subscription.ICM_1D_2D);

export const CS_SUBSCRIPTIONS = new Set<Subscription>();
CS_SUBSCRIPTIONS.add(Subscription.INNCS);

export const DEFAULT_SUBSCRIPTION_ID_SET = new Set<Subscription>();
DEFAULT_SUBSCRIPTION_ID_SET.add(Subscription.I360AFA);
DEFAULT_SUBSCRIPTION_ID_SET.add(Subscription.I360AFI);
DEFAULT_SUBSCRIPTION_ID_SET.add(Subscription.I360AV);
DEFAULT_SUBSCRIPTION_ID_SET.add(Subscription.I360IU);
DEFAULT_SUBSCRIPTION_ID_SET.add(Subscription.I360PU);
DEFAULT_SUBSCRIPTION_ID_SET.add(Subscription.INNCS);
