import { Subscription } from '../types/Subscription';

//==================================================================================
// Bit mask from subscription tags

export enum EntitlementMask {
    None = 0,
    Full = 1 << 0,
    Inspection = 1 << 1,
    Viewer = 1 << 2,
    Insight = 1 << 3,
    Plant = 1 << 4,
    CollaborationService = 1 << 5,
}

export const entitlementMask = (subscriptions: string[]): EntitlementMask => {
    let en = EntitlementMask.None;

    if (subscriptions && subscriptions.length > 0) {
        subscriptions.forEach((s) => {
            toString;
            switch (s) {
                case Subscription.ASSET_FULL_ACCESS:
                case Subscription.I360AFA:
                    en |= EntitlementMask.Full;
                    break;
                case Subscription.ASSET_INSPECTION_FIELD:
                case Subscription.I360AFI:
                    en |= EntitlementMask.Inspection;
                    break;
                case Subscription.ASSET_VIEWER_ONLY_ACCESS:
                case Subscription.I360AV:
                    en |= EntitlementMask.Viewer;
                    break;
                case Subscription.INSIGHT_T1:
                case Subscription.INSIGHT_T2:
                case Subscription.INSIGHT_T3:
                case Subscription.INSIGHT_CM:
                case Subscription.INFO360IU:
                case Subscription.I360IU:
                    en |= EntitlementMask.Insight;
                    break;
                case Subscription.PLANT_CM:
                case Subscription.PLANT_T1:
                case Subscription.PLANT_T2:
                case Subscription.PLANT_T3:
                case Subscription.PLANT_T4:
                case Subscription.I36P1CM:
                case Subscription.I36P1KM:
                case Subscription.I36P50M:
                case Subscription.I36P250:
                case Subscription.I360PU:
                    en |= EntitlementMask.Plant;
                    break;
                case Subscription.INNCS:
                    en |= EntitlementMask.CollaborationService;
                    break;
            }
        });
    }
    return en;
};

//==================================================================================
// Fundamental Buisness logic:
//
// Full and anything else === Full mode
// Any other combination === Viewer mode
// Just Inspections === Inspection mode
// Just Insight === Viewer mode
// Just Viewer === Viewer mode
// Just None === Viewer mode

// Full and anything else === Full mode
export const fullMode = (mask: EntitlementMask): boolean =>
    (mask & EntitlementMask.Full) === EntitlementMask.Full;

// Just Inspections === Inspection mode
export const inspectionMode = (mask: EntitlementMask): boolean =>
    mask === EntitlementMask.Inspection;

// All else defaults to viewer mode
export const viewerMode = (mask: EntitlementMask): boolean =>
    !isSet(mask, EntitlementMask.Full) && !inspectionMode(mask);

// Ignoring Full; all other combinations mean mixed and FE toast should warn user they have been demoted to viewer
export const mixedMode = (mask: EntitlementMask): boolean => {
    let count = 0;

    if (isSet(mask, EntitlementMask.Inspection)) {
        count += 1;
    }
    if (isSet(mask, EntitlementMask.Viewer)) {
        count += 1;
    }
    if (isSet(mask, EntitlementMask.Insight)) {
        count += 1;
    }
    if (isSet(mask, EntitlementMask.Plant)) {
        count += 1;
    }
    if (isSet(mask, EntitlementMask.CollaborationService)) {
        count += 1;
    }

    return count > 1;
};

//==================================================================================
// Utilities to aide debuging

const isSet = (mask: EntitlementMask, value: EntitlementMask): boolean =>
    (mask & value) === value;

export const toString = (mask: EntitlementMask): string =>
    mask
        .toString(2)
        .padStart((Object.keys(EntitlementMask).length - 1) / 2, '0');

export const entitlementInfo = (subscriptions: string[]): string => {
    const mask = entitlementMask(subscriptions);
    const csv = subscriptions.reduce((prev, cur) => (prev += `,${cur}`), '');

    const subs = `[${csv.length > 0 ? csv.slice(1) : ''}] = mask(${toString(
        mask,
    )})`;

    const fMode = `, full = ${fullMode(mask) ? 'T' : 'F'}`;
    const iMode = `, inspection = ${inspectionMode(mask) ? 'T' : 'F'}`;
    const vMode = `, view = ${viewerMode(mask) ? 'T' : 'F'}`;
    const eMode = `, insight = ${
        isSet(mask, EntitlementMask.Insight) ? 'T' : 'F'
    }`;
    const pMode = `, plant = ${isSet(mask, EntitlementMask.Plant) ? 'T' : 'F'}`;
    const cMode = `, collab = ${
        isSet(mask, EntitlementMask.CollaborationService) ? 'T' : 'F'
    }`;
    const dMode = `, (mixed = ${mixedMode(mask) ? 'T' : 'F'})`;

    return subs + fMode + iMode + vMode + eMode + pMode + cMode + dMode;
};

/* Used to validate/jest endpoint syntax, use...
    expect(validateMaskDict(validAccessModes)).toBe("");
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const validateMaskDict = (validAccessModes: any): string => {
    for (const k in validAccessModes) {
        if (
            !['get-', 'put-', 'post-', 'delete-'].some((x) => k.startsWith(x))
        ) {
            return `key ${k} invalid start CRUD`;
        }

        for (const c of k) {
            if (c === ' ') {
                return `key ${k} contains whitespace`;
            }

            if (c === c.toUpperCase()) {
                if (
                    // This is the obvious list but might need to add others:
                    // but if so should be questioning why?
                    // e.g. '_' is valid but generally dissaproved of; use '-' instead?
                    ![
                        '.',
                        '-',
                        '/',
                        '{',
                        '}',
                        '[',
                        ']',
                        '0',
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                    ].some((x) => c === x)
                ) {
                    return `key "${k}" has uppercase char '${c}'`;
                }
            }
        }
    }
    return '';
};

//==================================================================================
// Front end API
// Only valid modes for app_am_'s

export const fullAccess = (subscriptions: string[]): boolean =>
    fullMode(entitlementMask(subscriptions));

export const inspectionAccess = (subscriptions: string[]): boolean =>
    inspectionMode(entitlementMask(subscriptions));

export const viewerAccess = (subscriptions: string[]): boolean =>
    viewerMode(entitlementMask(subscriptions));

// Currently mixedAccess should be equivalent to viewerAccess
// Handy for toast warning messages
export const mixedAccess = (subscriptions: string[]): boolean =>
    mixedMode(entitlementMask(subscriptions));

//==================================================================================
// Back end API

// id MUST be all lower case
export type MaskDict = {
    [idLowerCaseOnly: string]: EntitlementMask;
};

export const isEntitled = (
    mask: EntitlementMask,
    key: string,
    maskDict: MaskDict,
): boolean => key in maskDict && (maskDict[key] & mask) === mask;

// From combined entitlements determine single valid entitlement and check crudRequest against that
export const hasEntitlement = (
    mask: EntitlementMask,
    key: string,
    validAccessModes: MaskDict,
): boolean => {
    if (fullMode(mask)) {
        return isEntitled(EntitlementMask.Full, key, validAccessModes);
    }

    if (isSet(mask, EntitlementMask.Insight)) {
        return isEntitled(EntitlementMask.Insight, key, validAccessModes);
    }

    if (inspectionMode(mask)) {
        return isEntitled(EntitlementMask.Inspection, key, validAccessModes);
    }

    if (isSet(mask, EntitlementMask.Plant)) {
        return isEntitled(EntitlementMask.Plant, key, validAccessModes);
    }

    if (isSet(mask, EntitlementMask.CollaborationService)) {
        return isEntitled(
            EntitlementMask.CollaborationService,
            key,
            validAccessModes,
        );
    }

    // ViewerMode is the default, always keep last
    if (viewerMode(mask)) {
        return isEntitled(EntitlementMask.Viewer, key, validAccessModes);
    }

    // This line currently cannot be reached as always default to viewMode at very least
    return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const crudRequest = (event: any): string =>
    `${event.method}-${event.requestPath}`.toLowerCase();

export const validEventAccess = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    event: any,
    subscriptions: string[],
    validAccessModes: MaskDict,
): boolean => {
    const key = crudRequest(event);
    const mask = entitlementMask(subscriptions);
    return hasEntitlement(mask, key, validAccessModes);
};

export const validEventAccessInfo = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    event: any,
    subscriptions: string[],
    validAccessModes: MaskDict,
): string => {
    const key = crudRequest(event);
    const info = entitlementInfo(subscriptions);
    const entitled = validEventAccess(event, subscriptions, validAccessModes);

    return `'${key}' access = ${entitled ? 'T' : 'F'}, ${info}`;
};
