export enum SensorType {
    Ammonia = 'Ammonia',
    Billing = 'Billing',
    Current = 'Current',
    Conductivity = 'Conductivity',
    Concentration = 'Concentration',
    ColorUnit = 'Color Unit',
    Currency = 'Currency',
    ElectricPotential = 'Electric Potential',
    FlowRate = 'Flow Rate',
    Fluoride = 'Fluoride',
    FreeAmmonia = 'Free Ammonia',
    Head = 'Head',
    HAA5 = 'HAA5',
    Level = 'Level',
    Length = 'Length',
    LinearChargeDensity = 'Linear Charge Density',
    Mass = 'Mass',
    Monochloramine = 'Monochloramine',
    Nitrite = 'Nitrite',
    Other = 'Other',
    pH = 'pH',
    Percent = 'Percent',
    Power = 'Power',
    Pressure = 'Pressure',
    RadonLevel = 'Radon Level',
    Setting = 'Setting',
    Status = 'Status',
    TankLevel = 'Tank Level',
    Temperature = 'Temperature',
    TotalHardness = 'Total Hardness',
    TotalTHMs = 'Total THMs',
    TotalChlorine = 'Total chlorine',
    TOC = 'TOC',
    ThresholdOdorNumber = 'Threshold Odor Number',
    TimeDuration = 'Time Duration',
    Turbidity = 'Turbidity',
    UVT = 'UVT',
    UV254 = 'UV254',
    Virtual = 'virtual', // this is usually ignored in some cases
    Volume = 'Volume',
    Velocity = 'Velocity',
    ChlorineResiduals = 'Chlorine Residuals',
    RainGauge = 'Rain Gauge',
    Ratio = 'Ratio',
}

export const SENSOR_TYPE_LIST = [
    SensorType.Ammonia,
    SensorType.Billing,
    SensorType.Current,
    SensorType.Conductivity,
    SensorType.Concentration,
    SensorType.ColorUnit,
    SensorType.Currency,
    SensorType.ElectricPotential,
    SensorType.FlowRate,
    SensorType.Fluoride,
    SensorType.FreeAmmonia,
    SensorType.Head,
    SensorType.HAA5,
    SensorType.Level,
    SensorType.Length,
    SensorType.LinearChargeDensity,
    SensorType.Mass,
    SensorType.Monochloramine,
    SensorType.Nitrite,
    SensorType.Other,
    SensorType.pH,
    SensorType.Percent,
    SensorType.Power,
    SensorType.Pressure,
    SensorType.RadonLevel,
    SensorType.Setting,
    SensorType.Status,
    SensorType.TankLevel,
    SensorType.Temperature,
    SensorType.TotalHardness,
    SensorType.TotalTHMs,
    SensorType.TotalChlorine,
    SensorType.TOC,
    SensorType.ThresholdOdorNumber,
    SensorType.TimeDuration,
    SensorType.Turbidity,
    SensorType.UVT,
    SensorType.UV254,
    SensorType.Volume,
    SensorType.Velocity,
    SensorType.ChlorineResiduals,
    SensorType.RainGauge,
    // Virtual is not included in the list
].sort((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
});

type NoUnits = 'No Units';
type FlowRateUnits =
    | 'gpm'
    | 'cfs'
    | 'ft3/s'
    | 'cfm'
    | 'ft3/m'
    | 'L/s'
    | 'L/m'
    | 'gph'
    | 'gpd'
    | 'ML/day'
    | 'MGD'
    | 'MG/day'
    | 'MG/hr'
    | 'm3/day'
    | 'm3/hr'
    | 'm3/s'
    | NoUnits;
type HeadUnits = 'ft' | 'm' | NoUnits;
type LevelUnits = 'ft' | 'm' | NoUnits;
type PowerUnits = 'kW' | 'Hp' | 'Watt' | NoUnits;
type PressureUnits = 'psi' | 'kPa' | 'm' | 'bar' | 'kg/cm2' | NoUnits;
type SettingUnits = NoUnits;
type StatusUnits = NoUnits;
type TankLevelUnits = 'ft' | 'in' | 'm' | NoUnits;
type TurbidityUnits = 'NTU' | 'NTRU' | 'FNU' | 'FNRU' | 'mg/L' | NoUnits;
type VolumeUnits =
    | 'gallon'
    | 'L'
    | 'MG'
    | 'ML'
    | 'Mft3'
    | 'ft3'
    | 'm3'
    | 'acre-foot'
    | NoUnits;
type BillingUnits = VolumeUnits;
type TemperatureUnits = 'C' | 'F' | NoUnits;
type CurrentUnits = 'amperes' | NoUnits;
type VelocityUnits = 'm/m' | 'ft/m' | 'm/s' | 'ft/s';
type ChlorineResidualsUnits = 'mg/L' | 'ppm';
type RainGaugeUnits = 'in/hr' | 'cm/hr' | 'mm/hr' | 'in' | 'cm' | 'mm';
type VirtualUnits = string | NoUnits;
type ConcentrationUnits =
    | '#/L'
    | 'µg/L'
    | 'CFU/100mL'
    | 'CFU/mL'
    | 'MFL'
    | 'mg/L'
    | 'MPN/100mL'
    | 'ng/L'
    | 'NU/mL'
    | 'pg/L';
type PercentUnits = '%';
type ConductivityUnits = 'µmhos/cm';
type LinearChargeDensityUnits = 'Abs/m' | 'Abs/cm';
type ColorUnitUnits = 'CU';
type LengthUnits = 'ft' | 'in' | 'cm' | 'm';
type TimeDurationUnits = 's' | 'm' | 'h' | 'd';
type ElectricPotentialUnits = 'mV';
type RadonLevelUnits = 'pCi/L';
type pHUnits = 'pH';
type ThresholdOdorNumberUnits = 'TON';
type MassUnits =
    | 'US Ton'
    | 'UK Ton'
    | 'Pounds'
    | 'Ounces'
    | 'Gigaton'
    | 'Megaton'
    | 'Ton'
    | 'Kilogram'
    | 'Gram'
    | 'Milligram'
    | 'Microgram'
    | 'Nanogram'
    | 'Picogram'
    | 'Dry Tons'
    | 'Wet Tons';
type HAA5Units = 'µg/L';
type TotalHardnessUnits = 'mg/L';
type TotalTHMsUnits = 'µg/L';
type TotalChlorineUnits = 'mg/L';
type FluorideUnits = 'mg/L';
type FreeAmmoniaUnits = 'mg/L';
type AmmoniaUnits = 'mg/L';
type NitriteUnits = 'mg/L';
type TOCUnits = 'mg/L';
type UV254Units = 'Abs/cm';
type MonochloramineUnits = 'mg/L';
type UVTUnits = '%';
type CurrencyUnits = 'Dollar' | 'Pound' | 'Euro';
type OtherUnits = NoUnits;

export interface SensorTypeUnits {
    [SensorType.FlowRate]: FlowRateUnits[];
    [SensorType.Head]: HeadUnits[];
    [SensorType.HAA5]: HAA5Units[];
    [SensorType.TotalHardness]: TotalHardnessUnits[];
    [SensorType.TotalTHMs]: TotalTHMsUnits[];
    [SensorType.TotalChlorine]: TotalChlorineUnits[];
    [SensorType.Fluoride]: FluorideUnits[];
    [SensorType.FreeAmmonia]: FreeAmmoniaUnits[];
    [SensorType.Ammonia]: AmmoniaUnits[];
    [SensorType.Nitrite]: NitriteUnits[];
    [SensorType.TOC]: TOCUnits[];
    [SensorType.UV254]: UV254Units[];
    [SensorType.Monochloramine]: MonochloramineUnits[];
    [SensorType.UVT]: UVTUnits[];
    [SensorType.Level]: LevelUnits[];
    [SensorType.Power]: PowerUnits[];
    [SensorType.Pressure]: PressureUnits[];
    [SensorType.Setting]: SettingUnits[];
    [SensorType.Status]: StatusUnits[];
    [SensorType.TankLevel]: TankLevelUnits[];
    [SensorType.Turbidity]: TurbidityUnits[];
    [SensorType.Volume]: VolumeUnits[];
    [SensorType.Billing]: BillingUnits[];
    [SensorType.Temperature]: TemperatureUnits[];
    [SensorType.Current]: CurrentUnits[];
    [SensorType.Velocity]: VelocityUnits[];
    [SensorType.ChlorineResiduals]: ChlorineResidualsUnits[];
    [SensorType.RainGauge]: RainGaugeUnits[];
    [SensorType.Virtual]: VirtualUnits[];
    [SensorType.Other]: OtherUnits[];
    [SensorType.Concentration]: ConcentrationUnits[];
    [SensorType.Percent]: PercentUnits[];
    [SensorType.Conductivity]: ConductivityUnits[];
    [SensorType.LinearChargeDensity]: LinearChargeDensityUnits[];
    [SensorType.ColorUnit]: ColorUnitUnits[];
    [SensorType.Length]: LengthUnits[];
    [SensorType.TimeDuration]: TimeDurationUnits[];
    [SensorType.ElectricPotential]: ElectricPotentialUnits[];
    [SensorType.RadonLevel]: RadonLevelUnits[];
    [SensorType.pH]: pHUnits[];
    [SensorType.ThresholdOdorNumber]: ThresholdOdorNumberUnits[];
    [SensorType.Mass]: MassUnits[];
    [SensorType.Currency]: CurrencyUnits[];
}

export const SENSOR_TYPE_UNITS_MAP: SensorTypeUnits = {
    [SensorType.Ammonia]: ['mg/L'],
    [SensorType.FlowRate]: [
        'gpm',
        'cfs',
        'ft3/s',
        'cfm',
        'ft3/m',
        'L/s',
        'L/m',
        'gph',
        'gpd',
        'ML/day',
        'MGD',
        'MG/day',
        'MG/hr',
        'm3/day',
        'm3/hr',
        'm3/s',
        'No Units',
    ],
    [SensorType.Fluoride]: ['mg/L'],
    [SensorType.FreeAmmonia]: ['mg/L'],
    [SensorType.Head]: ['ft', 'm', 'No Units'],
    [SensorType.HAA5]: ['µg/L'],
    [SensorType.Level]: ['ft', 'm', 'No Units'],
    [SensorType.Nitrite]: ['mg/L'],
    [SensorType.Monochloramine]: ['mg/L'],
    [SensorType.Power]: ['kW', 'Hp', 'Watt', 'No Units'],
    [SensorType.Pressure]: ['psi', 'kPa', 'm', 'bar', 'kg/cm2', 'No Units'],
    [SensorType.Setting]: ['No Units'],
    [SensorType.Status]: ['No Units'],
    [SensorType.TankLevel]: ['ft', 'in', 'm', 'No Units'],
    [SensorType.TotalChlorine]: ['mg/L'],
    [SensorType.TotalHardness]: ['mg/L'],
    [SensorType.TotalTHMs]: ['µg/L'],
    [SensorType.TOC]: ['mg/L'],
    [SensorType.Turbidity]: ['NTU', 'NTRU', 'FNU', 'FNRU', 'mg/L', 'No Units'],
    [SensorType.UV254]: ['Abs/cm'],
    [SensorType.UVT]: ['%'],
    [SensorType.Volume]: [
        'gallon',
        'L',
        'MG',
        'ML',
        'Mft3',
        'ft3',
        'm3',
        'acre-foot',
        'No Units',
    ],
    [SensorType.Billing]: [
        'gallon',
        'L',
        'MG',
        'ML',
        'Mft3',
        'ft3',
        'm3',
        'acre-foot',
        'No Units',
    ],
    [SensorType.Temperature]: ['C', 'F', 'No Units'],
    [SensorType.Current]: ['amperes', 'No Units'],
    [SensorType.Velocity]: ['m/m', 'ft/m', 'm/s', 'ft/s'],
    [SensorType.ChlorineResiduals]: ['mg/L', 'ppm'],
    [SensorType.RainGauge]: ['in/hr', 'cm/hr', 'mm/hr', 'in', 'cm', 'mm'],
    [SensorType.Virtual]: ['No Units'],
    [SensorType.Other]: ['No Units'],
    [SensorType.Concentration]: [
        '#/L',
        'µg/L',
        'CFU/100mL',
        'CFU/mL',
        'MFL',
        'mg/L',
        'MPN/100mL',
        'ng/L',
        'NU/mL',
        'pg/L',
    ],
    [SensorType.Percent]: ['%'],
    [SensorType.Conductivity]: ['µmhos/cm'],
    [SensorType.LinearChargeDensity]: ['Abs/m', 'Abs/cm'],
    [SensorType.ColorUnit]: ['CU'],
    [SensorType.Length]: ['ft', 'in', 'cm', 'm'],
    [SensorType.TimeDuration]: ['s', 'm', 'h', 'd'],
    [SensorType.ElectricPotential]: ['mV'],
    [SensorType.RadonLevel]: ['pCi/L'],
    [SensorType.pH]: ['pH'],
    [SensorType.ThresholdOdorNumber]: ['TON'],
    [SensorType.Mass]: [
        'US Ton',
        'UK Ton',
        'Pounds',
        'Ounces',
        'Gigaton',
        'Megaton',
        'Ton',
        'Kilogram',
        'Gram',
        'Milligram',
        'Microgram',
        'Nanogram',
        'Picogram',
        'Dry Tons',
        'Wet Tons',
    ],
    [SensorType.Currency]: ['Dollar', 'Pound', 'Euro'],
};

export const SENSOR_TYPE_LIST_WITH_VIRTUAL = [
    ...SENSOR_TYPE_LIST,
    SensorType.Virtual,
].sort();

interface Geometry {
    coordinates: number[];
    type: string;
}

export interface Sensor {
    _id: string;
    assetType?: string;
    sensorId?: string;
    assetId?: string;
    sensorType?: SensorType;
    geometry?: Geometry;
    x?: number;
    y?: number;
    z?: number;
    alias?: string;
    locationDescription?: string;
}
