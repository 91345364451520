import { createPrivateKey } from 'crypto';

export const decodePrivateKey = async (
    privateKey: string,
    passphrase: string,
): Promise<string | Buffer> => {
    const privateKeyObject = createPrivateKey({
        key: privateKey,
        format: 'pem',
        passphrase,
    });

    return privateKeyObject.export({
        format: 'pem',
        type: 'pkcs8',
    });
};
