type FCRegexTypeName = 'whitelist' | 'blacklist';
type FCCharsRegexType = Record<FCRegexTypeName, RegExp>;
type FCFieldConfiguration = {
    placeholderChar: string;
    charsRegex: FCCharsRegexType;
};

export type FCFieldConfig<T extends string> = Record<T, FCFieldConfiguration>;

export abstract class FormConverterBase<T extends string> {
    public abstract readonly FieldConfig: FCFieldConfig<T>;

    public transformField(
        fieldName: T,
        value: string,
        regexType: FCRegexTypeName = 'blacklist',
    ): string {
        if (!value) return value;
        const {
            FieldConfig: {
                [fieldName]: { charsRegex, placeholderChar },
            },
        } = this;
        return value.replace(charsRegex[regexType], placeholderChar);
    }
}
