import { Extent } from './geojson';

// distance between two lon/lat points in m
export const haversine = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
): number => {
    const radEarth = 6378.137; // Radius of earth in KM
    const dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
    const dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) *
            Math.cos((lat2 * Math.PI) / 180) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = radEarth * c;
    return d * 1000; // meters
};

// Think carefully - do you really need > 6?, seen 13 which is Angstrom scales!
// decimal  degrees    distance
// places
// -------------------------------
// 0        1.0        111 km
// 1        0.1        11.1 km
// 2        0.01       1.11 km
// 3        0.001      111 m
// 4        0.0001     11.1 m
// 5        0.00001    1.11 m
// 6        0.000001   0.111 m
// 7        0.0000001  1.11 cm
// 8        0.00000001 1.11 mm
//
// There are differences wrt lat distortion near the poles
// but pretty small so once again; does it matter?
//
export const lonLatPrecision = 6;

// Round lon or lat value
export const lonLatRound = (
    value: number,
    precision = lonLatPrecision,
): number => {
    const pow = Math.pow(10, precision);

    if (value >= 0) {
        return Math.ceil(value * pow) / pow;
    }

    return Math.floor(value * pow) / pow;
};

// Round lon or lat value and ensure in valid range
export const lonLatRoundLimited = (
    value: number,
    precision = lonLatPrecision,
    minValue: number | undefined = undefined,
    maxValue: number | undefined = undefined,
): number => {
    if (value < minValue) {
        return lonLatRound(minValue, precision);
    }

    if (value > maxValue) {
        return lonLatRound(maxValue, precision);
    }

    return lonLatRound(value, precision);
};

// Truncates number to a sensible longitude precision and ensures in bounds
export const lonValid = (lon: number, precision = lonLatPrecision): number =>
    lonLatRoundLimited(lon, precision, -180, 180);

// Truncates number to a sensible latitude precision and ensures in bounds
export const latValid = (lon: number, precision = lonLatPrecision): number =>
    lonLatRoundLimited(lon, precision, -90, 90);

// get a sensible lon/lat extent if possible
export const fixLonLatExtent = (
    extent: Extent | undefined,
    minOffset: number | undefined = undefined, // degrees
    precision = lonLatPrecision,
): Extent | undefined => {
    if (!extent) {
        return undefined;
    }

    let ex: Extent = [
        lonValid(extent[0], precision),
        latValid(extent[1], precision),
        lonValid(extent[2], precision),
        latValid(extent[3], precision),
    ];

    if (ex[0] > ex[2]) {
        const x = ex[0];
        ex[0] = ex[2];
        ex[2] = x;
    }

    if (ex[1] > ex[3]) {
        const x = ex[1];
        ex[1] = ex[3];
        ex[3] = x;
    }

    if (minOffset) {
        const dx = haversine(ex[0], ex[1], ex[2], ex[1]);

        if (dx < minOffset) {
            const x = 0.5 * (ex[0] + ex[2]);
            ex = [x - minOffset, ex[1], x + minOffset, ex[3]];
        }

        const dy = haversine(ex[0], ex[1], ex[0], ex[3]);

        if (dy < minOffset) {
            const y = 0.5 * (ex[1] + ex[3]);
            ex = [ex[0], y - minOffset, ex[2], y + minOffset];
        }
    }

    return ex;
};

// get a sensible lon/lat extent if possible
export const toLonLatExtent = (
    arr: number[] | undefined,
    minOffset: number | undefined = undefined, // degrees
    precision = lonLatPrecision,
): Extent | undefined =>
    fixLonLatExtent(
        arr?.length === 4 ? [arr[0], arr[1], arr[2], arr[3]] : undefined,
        minOffset,
        precision,
    );
